import { sprintf } from 'sprintf-js';

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Home = () => import(/* webpackChunkName: "group-home" */ '@/views/Home');
// const Resources = () => import(/* webpackChunkName: "group-main" */ '@/views/Resources');
const Pro = () => import(/* webpackChunkName: "group-pro" */ '@/views/Pro');

const Tools = () => import(/* webpackChunkName: "group-extension" */ '@/views/tools/Tools');
const RemConverter = () => import(/* webpackChunkName: "group-extension" */ '@/views/tools/RemConverter');

const Master = () => import(/* webpackChunkName: "group-user" */ '@/views/Master');
const UserAccount = () => import(/* webpackChunkName: "group-user" */ '@/views/UserAccount');
const Library = () => import(/* webpackChunkName: "group-user" */ '@/views/Library');
const Collections = () => import(/* webpackChunkName: "group-user" */ '@/views/Collections');
//const Settings = () => import(/* webpackChunkName: "group-user" */ '@/views/Settings');
const Migrate = () => import(/* webpackChunkName: "group-user" */ '@/views/Migrate');

const Auth = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/Auth');
const SignIn = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/SignIn');
const SignOut = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/SignOut');
const CreateAccount = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/CreateAccount');
const VerifyAccount = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/VerifyAccount');
const ForgotPassword = () => import(/* webpackChunkName: "group-auth" */ '@/views/auth/ForgotPassword');

const Admin = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/Admin');
const AdminDashboard = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminDashboard');
const AdminUsers = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminUsers');
const AdminNotifications = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminNotifications');
const AdminOffers = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminOffers');
const AdminCoupons = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminCoupons');

const Support = () => import(/* webpackChunkName: "group-onboarding" */ '@/views/support/Support');
const Terms = () => import(/* webpackChunkName: "group-support" */ '@/views/support/Terms');
const Privacy = () => import(/* webpackChunkName: "group-support" */ '@/views/support/Privacy');
const Newsletter = () => import(/* webpackChunkName: "group-newsletter" */ '@/views/support/Newsletter');
const Permissions = () => import(/* webpackChunkName: "group-support" */ '@/views/support/Permissions');
const Status = () => import(/* webpackChunkName: "group-support" */ '@/views/support/Status');
const Features = () => import(/* webpackChunkName: "group-onboarding" */ '@/views/support/Features');
const Onboarding = () => import(/* webpackChunkName: "group-onboarding" */ '@/views/support/Onboarding');
const FAQs = () => import(/* webpackChunkName: "group-onboarding" */ '@/views/support/FAQs');
const About = () => import(/* webpackChunkName: "group-about" */ '@/views/support/About');
const Advertising = () => import(/* webpackChunkName: "group-about" */ '@/views/support/Advertising');
const Notifications = () => import(/* webpackChunkName: "group-user" */ '@/views/support/Notifications');
const Goodbye = () => import(/* webpackChunkName: "group-extension" */ '@/views/support/Goodbye');

const NotFound = () => import(/* webpackChunkName: "group-errors" */ '@/views/NotFound')
const ComingSoon = () => import(/* webpackChunkName: "group-maintenance" */ '@/views/ComingSoon');

const requiresAuth = true;
const requiresAdmin = true;
const hasSearch = true;
const goToSignIn = true;
const loggedOutOnly = true;
const ignoreRoute = true;

const metaDescription = 'Customize any website in seconds with CSS. Amino is a compact and powerful browser extension that allows you to customize any website with CSS that persists. Use the editor to create your own website themes and skins.';

const routes = [
	{
		path: '/',
		component: Home,
		name: 'home',
		meta: {
			loggedOutOnly,
			seo: {
				metaTags: [
					{
						name: 'description',
						content: metaDescription
					}
				],
				richSnippet: {
					"@context": "https://schema.org",
					"@type": "Project",
					"name": "Amino",
					"url": "https://aminoeditor.com",
					"logo": "https://aminoeditor.com/images/logo.png",
					"sameAs": [
						"https://twitter.com/aminoeditor",
						"https://github.com/aminoeditor"
					]
				}
			}
		}
	},
	{
		path: '',
		component: Support,
		children: [
			{
				path: '/features',
				component: Features,
				meta: {
					seo: {
						title: 'Features',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/onboarding',
				component: Onboarding,
				meta: {
					seo: {
						title: 'Onboarding',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/newsletter',
				component: Newsletter,
				meta: {
					page: {
						title: 'Newsletter'
					},
					seo: {
						title: 'Newsletter',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/status',
				component: Status,
				meta: {
					seo: {
						title: 'Status',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/notifications',
				component: Notifications,
				meta: {
					requiresAuth,
					goToSignIn,
					sitemap: {
						ignoreRoute
					},
					page: {
						title: 'Notifications'
					},
					seo: {
						title: 'Notifications'
					}
				}
			},
			{
				path: '/privacy',
				component: Privacy,
				meta: {
					seo: {
						title: 'Privacy Policy',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/terms',
				component: Terms,
				meta: {
					seo: {
						title: 'Terms of Use',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/permissions',
				component: Permissions,
				meta: {
					seo: {
						title: 'Permissions',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/faqs',
				component: FAQs,
				meta: {
					seo: {
						title: 'FAQs',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/about',
				component: About,
				meta: {
					seo: {
						title: 'About',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/advertising',
				component: Advertising,
				meta: {
					seo: {
						title: 'Advertising',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/goodbye',
				component: Goodbye,
				meta: {
					sitemap: {
						ignoreRoute
					},
					seo: {
						title: 'Goodbye',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			}
		]
	},
	{
		path: '',
		component: Master,
		children: [
			{
				path: '/library/:style_id?',
				component: Library,
				name: 'library',
				meta: {
					requiresAuth,
					goToSignIn,
					hasSearch,
					sitemap: {
						ignoreRoute
					},
					seo: {
						title: 'Library'
					}
				}
			},
			{
				path: '/collections/:collection_id?/:style_id?',
				component: Collections,
				name: 'collections',
				meta: {
					requiresAuth,
					goToSignIn,
					hasSearch,
					sitemap: {
						ignoreRoute
					},
					seo: {
						title: 'Collections'
					}
				}
			},
			{
				path: '/migrate',
				component: Migrate,
				meta: {
					requiresAuth,
					goToSignIn,
					sitemap: {
						ignoreRoute
					},
					seo: {
						title: 'Migrate'
					}
				}
			}
		]
	},
	{
		path: '/account',
		component: UserAccount,
		name: 'account',
		meta: {
			requiresAuth,
			goToSignIn,
			sitemap: {
				ignoreRoute
			},
			page: {
				title: 'Account'
			},
			seo: {
				title: 'Settings'
			}
		},
		children: [
			{
				path: '/account/plans',
				component: UserAccount,
				name: 'plans',
				meta: {
					requiresAuth,
					goToSignIn,
					sitemap: {
						ignoreRoute
					},
					page: {
						title: 'Account - Plans'
					},
					seo: {
						title: 'Plans',
						metaTags: [
							{
								name: 'description',
								content: ''
							}
						],
					}
				}
			},
			{
				path: '/account/data',
				component: UserAccount,
				name: 'data',
				meta: {
					requiresAuth,
					goToSignIn,
					sitemap: {
						ignoreRoute
					},
					page: {
						title: 'Account - Data'
					},
					seo: {
						title: 'Account - Data'
					}
				}
			},
			{
				path: '/account/approve-email/:key',
				component: UserAccount,
				name: 'approve-email',
				meta: {
					requiresAuth,
					goToSignIn,
					sitemap: {
						ignoreRoute
					},
					page: {
						title: 'Account - Approve Email'
					}
				}
			}
		]
	},
	{
		path: '/pro/:flag?',
		component: Pro,
		name: 'pro',
		meta: {
			sitemap: {
				loc: '/pro'
			},
			page: {
				title: 'Pro'
			},
			seo: {
				title: 'Pro',
				metaTags: [
					{
						name: 'description',
						content: 'Amino is used by thousands of web developers, designers, and product folks. Explore our plans and pricing to find the best fit for you.'
					}
				]
			}
		}
	},
	{
		path: '',
		component: Auth,
		children: [
			{
				name: 'sign-in',
				path: '/sign-in/:flag?',
				component: SignIn,
				meta: {
					loggedOutOnly,
					sitemap: {
						loc: '/sign-in'
					},
					seo: {
						title: 'Sign In',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/sign-out',
				component: SignOut,
				meta: {
					sitemap: {
						ignoreRoute
					}
				}
			},
			{
				name: 'create-account',
				path: '/create-account',
				component: CreateAccount,
				meta: {
					loggedOutOnly,
					seo: {
						title: 'Sign Up',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			},
			{
				path: '/verify-account/:key',
				component: VerifyAccount,
				meta: {
					sitemap: {
						ignoreRoute
					}
				}
			},
			{
				path: '/forgot-password/:key?',
				component: ForgotPassword,
				meta: {
					loggedOutOnly,
					sitemap: {
						ignoreRoute
					},
					seo: {
						title: 'Forgot Password',
						metaTags: [
							{
								name: 'description',
								content: metaDescription
							}
						],
					}
				}
			}
		]
	},
	{
		path: '/tools',
		component: Tools,
		children: [
			{
				path: '/tools/rem-converter',
				component: RemConverter,
				meta: {
					sitemap: {
						loc: '/tools/rem-converter'
					},
					page: {
						title: 'PX to REM Converter'
					},
					seo: {
						title: 'PX to REM Converter',
						metaTags: [
							{
								name: 'description',
								content: 'CSS calculator to convert px to rem units. Learn how to use rem units to create scalable web layouts.'
							}
						]
					}
				}
			}
		]
	},
	{
		path: '/admin',
		component: Admin,
		meta: {
			requiresAuth,
			requiresAdmin,
			goToSignIn,
			sitemap: {
				ignoreRoute
			}
		},
		children: [
			{
				path: '',
				redirect: '/admin/dashboard',
				component: AdminDashboard
			},
			{
				path: '/admin/dashboard',
				component: AdminDashboard
			},
			{
				path: '/admin/users',
				component: AdminUsers
			},
			{
				path: '/admin/notifications',
				component: AdminNotifications
			},
			{
				path: '/admin/offers',
				component: AdminOffers
			},
			{
				path: '/admin/coupons',
				component: AdminCoupons
			}
		]
	},
	{
		path: '/not-found',
		component: NotFound,
		meta: {
			sitemap: {
				ignoreRoute
			}
		}
	},
	{
		name: 'coming-soon',
		path: '/coming-soon',
		component: ComingSoon,
		meta: {
			sitemap: {
				ignoreRoute
			}
		}
	},
	{ path: '*', component: NotFound }
];

const router = new VueRouter({
	mode: 'history',
	scrollBehavior (to, from, savedPosition) {
		if (to.hash) {
			return { selector: to.hash };
		} else if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes
});

const guards =  {
	init (store, maintenance) {
		router.beforeEach((to, from, next) => {
			/* HANDLE ADMIN GUARDS */
			if (maintenance) {
				if (to.query.lemmein == 'true') {
					window.localStorage.setItem('lemmein', true);
					router.push('/');
				} else if (!window.localStorage.getItem('lemmein') && to.name != 'coming-soon') {
					router.push('/coming-soon');
					return;
				}
			} else if (to.name == 'coming-soon') {
				router.push('/');
				return;
			}
			const user = store.getters["auth/user"];
			const isAuthenticated = store.getters["auth/isAuthenticated"];
			const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
			const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
			const goToSignIn = to.matched.some(record => record.meta.goToSignIn);
			const loggedOutOnly = to.matched.some(record => record.meta.loggedOutOnly);

			const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.seo && r.meta.seo.title);
			const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.seo && r.meta.seo.metaTags);
			if( nearestWithTitle ) { document.title = sprintf('%s - Amino', nearestWithTitle.meta.seo.title); }
			else { document.title = 'Amino: Live CSS Editor'; }

			Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
			if ( nearestWithMeta ) {
				nearestWithMeta.meta.seo.metaTags.map(tagDef => {
				const tag = document.createElement('meta');
				Object.keys(tagDef).forEach(key => {
					tag.setAttribute(key, tagDef[key]);
				});
				tag.setAttribute('data-vue-router-controlled', '');
				return tag;
				}).forEach(tag => document.head.appendChild(tag));
			}
			if (requiresAuth && !isAuthenticated) {
				if (to.name === 'home') {
					next();
				} else if (goToSignIn) {
					router.push('/sign-in?redirect=' + encodeURIComponent(to.fullPath.substring(1)));
				} else {
					router.push('/');
				}
			} else if (loggedOutOnly && isAuthenticated) {
				router.push({
					path: '/library',
					query: to.query
				});
			} else if (requiresAdmin && !user.roles.includes("admin")) {
				router.push('/not-found');
			} else {
				next();
			}
		});
	}
};

export { routes, router, guards }
