import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client';
import { chromeStorageCacheUpdate, chromeStorageEventEmitter } from '../helpers';

class Styles extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Styles'
  // Define default properties here
  static instanceDefaults() {
    return {
      key: '',
      val: '',
      active: true,
      mode: 0,
      isFavorite: false
    }
  }
}
const servicePath = 'styles'
const servicePlugin = makeServicePlugin({
  Model: Styles,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [ chromeStorageCacheUpdate ],
    update: [ chromeStorageCacheUpdate ],
    patch: [ chromeStorageCacheUpdate ],
    remove: [ chromeStorageCacheUpdate ]
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [ chromeStorageEventEmitter ],
    update: [ chromeStorageEventEmitter ],
    patch: [ chromeStorageEventEmitter ],
    remove: [ chromeStorageEventEmitter ]
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
