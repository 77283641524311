import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'
import { chromeStorageCacheUpdate, chromeStorageEventEmitter } from '../helpers';

class Options extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Options'
  // Define default properties here
  static instanceDefaults() {
    return {
        autocomplete: true,
        autoupdate: false,
        popout: false,
        context: true,
        gutter: true,
        theme: 'dark',
        logMode: 'emerg'
    };
  }
}
const servicePath = 'options'
const servicePlugin = makeServicePlugin({
  Model: Options,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [ chromeStorageCacheUpdate ],
    update: [ chromeStorageCacheUpdate ],
    patch: [ chromeStorageCacheUpdate ],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [chromeStorageEventEmitter],
    update: [chromeStorageEventEmitter],
    patch: [chromeStorageEventEmitter],
    remove: [chromeStorageEventEmitter]
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
