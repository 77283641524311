// Dependencies
import Vue from 'vue';
import CompositionApi from '@vue/composition-api';

Vue.use(CompositionApi);

// Application Base Component
const App = () => import('@/App');

// Modules
import { router, guards } from '@/modules/router';
import store from '@/modules/amino-vue-store';
import '@/modules/vendors';
import '@/modules/mixins';

import analytics from '@/modules/analytics';
analytics.init({
	ga: {
		//id: 'UA-89368242-2'
		id: 'G-EQET8BXSXK'
	}
}, router);

guards.init(store, (process.env.VUE_APP_MAINTENANCE == 'true' ? true : false));

// Make PWA
import './registerServiceWorker'

Vue.config.productionTip = false;
store.dispatch('auth/authenticate')
.catch(() => {}).then(() => {
  new Vue({
    render: h => h(App),
    router,
    store,
  }).$mount('#app');
});
