const extensionId = 'pbcpfbcibpcbfbmddogfhcijfpboeaaf';
const getCacheKey = (path, append='') => {
	return `__amino_${path}${append}`;
};
const localStorage = window?.chrome?.storage?.local;
const syncStorage  = window?.chrome?.storage?.sync;
const runtime      = window?.chrome?.runtime;

const updateCache  = async (key, val, method, params) => {
	if (method === 'remove') {
		await localStorage.remove([getCacheKey('styles', `_${params.key}`)]);
	} else {
		if (localStorage) {
			await localStorage.set({ [key]: JSON.stringify(val) })
		}
	}
};

const cacheHandlers = {
	options (data, method, params) {
		updateCache(getCacheKey('options'), data, method, params);
	},
	styles (data, method, params) {
		updateCache(getCacheKey('styles', `_${data.key}`), data, method, params);
	}
};
const chromeStorageCacheUpdate = context => {
	const { data={}, path, method, params } = context;
	if (cacheHandlers[path]) {
		cacheHandlers[path](data, method, params);
	}
};
const chromeStorageEventEmitter = context => {
	const {
		method,
		path,
		params: {
			authentication: {
				payload: {
					jti
				}
			}
		},
		result: {
			_id,
			key
		}
	} = context;
	let event;
	if (['patch'].includes(context.method)) {
		event = `${method}ed`;
	} else {
		event = `${method}d`;
	}
	const eventKey = `__amino_service_event_${path}`;
	const serviceEvent = {
		[eventKey]: JSON.stringify({ event, _id, key, jti, time: Date.now() })
	};
	if (syncStorage) {
		syncStorage.set(serviceEvent);
	} else if (runtime) {
		runtime.sendMessage(extensionId, { serviceEvent });
	}
};

export { chromeStorageCacheUpdate, chromeStorageEventEmitter }
