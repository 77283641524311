import Vue from 'vue';
Vue.mixin({
	data () {
		return {
			extensionId: 'pbcpfbcibpcbfbmddogfhcijfpboeaaf',
			confirmModalOpts: {
				size: 'sm',
				buttonSize: 'sm',
				cancelVariant: 'outline-dark',
				centered: true
			}
		}
	},
	computed: {
		redirect () {
			return this.$route.query.redirect || false;
		},
		user () {
			return this.$store.getters["auth/user"] || {};
		},
		isAuthenticated () {
			return this.$store.getters["auth/isAuthenticated"];
		},
		isVerified () {
			return this.user.isVerified || this.user.oauthVerified || false;
		},
		isPro () {
			const activeStatus = ['active', 'trialing'];
			return this.isAuthenticated ? (activeStatus.includes(this.user.subscription.status) || false) : false;
		},
		isPastDue () {
			const pastDueStatus = ['past_due', 'incomplete'];
			return this.isAuthenticated ? (pastDueStatus.includes(this.user.subscription.status) || false) : false;
		},
		stripeKey () {
			return process.env.VUE_APP_STRIPE_KEY;
		},
		apiURL () {
			return process.env.VUE_APP_AMINO_API_URL;
		},
		hasAnnualCoupon () {
			return process.env.VUE_APP_COUPON_ANNUAL.length;
		},
		annualCouponBadge () {
			return process.env.VUE_APP_COUPON_ANNUAL_BADGE;
		},
		annualCouponPrice () {
			return process.env.VUE_APP_COUPON_ANNUAL_PRICE;
		}
	},
	methods: {
		base64Encode (val) {
			return window.btoa(val);
		},
		getValidationState({
			dirty,
			validated,
			valid = null
		}) {
			return dirty || validated ? valid : null;
		},
		classList (element, method, ...args) {
			const root = document.getElementsByTagName(element)[0];
			root.classList[method](...args);
		},
		getUrlParts(url) {
			const el = document.createElement('a');
			el.href = url;
			return el;
		},
		getExtensionLink (provider) {
			let url;
			switch(provider)
			{
				case 'chrome': url = 'https://chrome.google.com/webstore/detail/';
				break;
				case 'brave': url = 'https://chrome.google.com/webstore/detail/';
				break;
			}
			return url + this.extensionId;
		},
		getAPIUrl (path) {
			return process.env.VUE_APP_AMINO_API_URL + path;
		},
		makeToast(body) {
			this.$bvToast.toast(body, {
				autoHideDelay: 2000,
				noCloseButton: true,
				toaster: 'b-toaster-bottom-center'
			})
		},
		loadGoogleOneTap () {
			if (!this.isAuthenticated && this.$route.params.flag != 'oauth-success') {
				this.includeScript('https://accounts.google.com/gsi/client', () => {
					const options = {
						client_id: process.env.VUE_APP_GOOGLE_OAUTH_ID,
						auto_select: false, // optional
						cancel_on_tap_outside: false, // optional
						context: 'signin', // optional
					};
					window.google.accounts.id.initialize({
						client_id: options.client_id,
						callback: (response) => {
							const { credential } = response;
							this.$store.dispatch('auth/authenticate', {
								strategy: 'onetap',
								credential
							})
							.then(({ accessToken }) => {
								this.loginExtension(accessToken);
								if (this.redirect) {
									this.$router.push('/' + this.redirect);
								} else {
									this.$router.push('/library');
								}
							})
							.catch(err => {
								console.log(err);
							});
						},
						auto_select: options.auto_select,
						cancel_on_tap_outside: options.cancel_on_tap_outside,
						context: ['signin', 'signup', 'use'].includes(options.context) ? options.context : 'signin'
					});
					window.google.accounts.id.prompt();
				});
			}
		},
		removeGoogleOneTap () {
			const oneTap = document.getElementById('credential_picker_container');
			if (oneTap) { oneTap.remove(); }
		},
		loginExtension (accessToken) {
			if (window.chrome && window.chrome.runtime) {
				window.chrome.runtime.sendMessage(this.extensionId, { accessToken }, () => window.chrome.runtime.lastError);
			}
		},
		logoutExtension () {
			if (window.chrome && window.chrome.runtime) {
				window.chrome.runtime.sendMessage(this.extensionId, { logout: true }, () => window.chrome.runtime.lastError);
			}
		},
		extensionExists () {
			if (window.chrome && window.chrome.runtime) {
				return new Promise(resolve => {
					window.chrome.runtime.sendMessage(this.extensionId, { hello: true }, res => {
						if (window.chrome.runtime.lastError) {
							resolve(false);
						}
						else if (res === 'hi') {
							resolve(true);
						} else {
							resolve(false);
						}
					});
				});
			} else {
				return Promise.resolve(false);
			}
		},
		includeScript ( URL, callback, async ) {
			const registerCallback = script => {
				script.addEventListener('load', (e) => {
					callback(e);
				}, false);
			};
			async = typeof async === "undefined" ? false : true;
			const scripts = document.getElementsByTagName('script');
			for (let i = scripts.length; i--;) {
				if (scripts[i].src == URL && callback) {
					registerCallback(scripts[i]);
				}
			}
			let documentTag = document, tag = 'script',
				object = documentTag.createElement(tag),
				scriptTag = documentTag.getElementsByTagName(tag)[0];
			object.src = URL;
			object.defer = true;
			object.async = async;
			if (callback) {
				registerCallback(object);
			}
			scriptTag.parentNode.insertBefore(object, scriptTag);
		},
		cleanQuery (...args) {
			const keys = Array.from(args);
			let query = Object.assign({}, this.$route.query);
			for (let key of keys) {
				delete query[key];
			}
			this.$router.replace({ query });
		}
	}
});
