import Vue from 'vue';

// Bootstrap Vue
import '@/modules/vendor/bootstrap';

// Filter Pretty Bytes
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes';
Vue.use(vueFilterPrettyBytes);

// Ace Editor
import VueAce from '@aminoeditor/vue-ace';
Vue.use(VueAce);

// Clipboard
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// Moment
Vue.use(require('vue-moment'));

// Waypoint plugin
import VueWaypoint from 'vue-waypoint'; 
Vue.use(VueWaypoint);

// Lazyload plugin
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
