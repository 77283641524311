import Vue from 'vue';
import VueGtag from "vue-gtag";
//import Hotjar from 'vue-hotjar'
const analytics =  {
	init (config, router) {
		Vue.use(VueGtag, {
			config: config.ga
		}, router);
		//Vue.use(Hotjar, config.hotjar);
	}
};

export default analytics
