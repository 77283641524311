import Vue from 'vue';

import {
	AvatarPlugin,
	BadgePlugin,
	ButtonPlugin,
	CardPlugin,
	CollapsePlugin,
	FormCheckboxPlugin,
	FormGroupPlugin,
	FormInputPlugin,
	FormPlugin,
	FormRadioPlugin,
	FormSelectPlugin,
	InputGroupPlugin,
	JumbotronPlugin,
	LayoutPlugin,
	LinkPlugin,
	ListGroupPlugin,
	ModalPlugin,
	NavPlugin,
	OverlayPlugin,
	PopoverPlugin,
	ProgressPlugin,
	SidebarPlugin,
	SkeletonPlugin,
	SpinnerPlugin,
	TablePlugin,
	TabsPlugin,
	ToastPlugin,
	TooltipPlugin
} from 'bootstrap-vue/dist/bootstrap-vue.esm.js';
Vue.use(AvatarPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(InputGroupPlugin);
Vue.use(JumbotronPlugin);
Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(NavPlugin);
Vue.use(OverlayPlugin);
Vue.use(PopoverPlugin);
Vue.use(ProgressPlugin);
Vue.use(SidebarPlugin);
Vue.use(SkeletonPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
