import Vue from 'vue';
import Vuex from 'vuex';
//import VuexPersistence from 'vuex-persist';
import { FeathersVuex } from '../feathers-client';
import auth from './store.auth';

Vue.use(Vuex)
Vue.use(FeathersVuex)

/*const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});*/

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
);

const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

const storage = {
  state: {},
  mutations: {},
  actions: {},
  plugins: [
    ...servicePlugins,
    auth,
//    vuexLocal.plugin
  ]
};

export default new Vuex.Store(storage);
